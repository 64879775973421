type GTagEvent = {
  category: string;
  label: string;
  value: number;
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: URL): void => {
  window.gtag(
    'config',
    `${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID}`,
    {
      page_path: url,
    },
  );
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const GAEvent = (
  name: string,
  { category, label, value }: GTagEvent,
): void => {
  if (!window.gtag) {
    return;
  }

  window.gtag('event', name, {
    event_category: category,
    event_label: label,
    value,
    non_interaction: true,
  });
};
