import { PerformanceTimingPlugin } from '@snowplow/browser-plugin-performance-timing';
import {
  newTracker,
  trackPageView as snowplowTrackPageView,
  trackStructEvent,
} from '@snowplow/browser-tracker';

import { SNOWPLOW_ENDPOINT } from '../src/constants';

type Events = 'open_select_api_version';

type Categories = 'click';

const initTracker = () => {
  if (!SNOWPLOW_ENDPOINT) {
    return;
  }

  newTracker('sp1', SNOWPLOW_ENDPOINT, {
    appId: 'developers_portal',
    platform: 'web',
    plugins: [PerformanceTimingPlugin()],
  });
};

const trackPageView = () => {
  snowplowTrackPageView();
};

const trackClick = (category: Categories, event: Events, label?: string) => {
  trackStructEvent({
    action: event,
    category,
    label,
  });
};

export { trackPageView, trackClick };

export default initTracker;
