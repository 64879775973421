const API_URL = process.env.NEXT_PUBLIC_API_URL as string;
const ACCOUNT_URL = process.env.NEXT_PUBLIC_ACCOUNT_URL as string;
const SANDBOX_URL = process.env.NEXT_PUBLIC_SANDBOX_URL as string;
const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN as string;
const ANALYTICS_TAG = process.env
  .NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID as string;
const SNOWPLOW_ENDPOINT = process.env.NEXT_PUBLIC_SNOWPLOW_ENDPOINT as string;
const YOUTUBE_PLAYLIST_ID = process.env
  .NEXT_PUBLIC_YOUTUBE_PLAYLIST_ID as string;
const GOOGLE_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_API_KEY as string;

if (!API_URL) {
  throw new Error('NEXT_PUBLIC_API_URL not defined.');
}

if (!ACCOUNT_URL) {
  throw new Error('NEXT_PUBLIC_ACCOUNT_URL not defined.');
}

if (!SANDBOX_URL) {
  throw new Error('NEXT_PUBLIC_SANDBOX_URL not defined.');
}

if (process.env.NODE_ENV === 'production' && !SENTRY_DSN) {
  throw new Error('NEXT_PUBLIC_SENTRY_DSN not defined.');
}

const IS_USING_SENTRY = SENTRY_DSN !== undefined;

const HOTJAR_ID = '3101476';

const INDEX_URL = '/';
const DOCS_URL = '/docs';
const COMMUNITY_URL = '/community';
const HELP_URL = '/help';
const ARQUIVEI_JOBS_URL = 'https://arquivei.com.br/vagas';

export {
  API_URL,
  ACCOUNT_URL,
  SANDBOX_URL,
  SENTRY_DSN,
  ANALYTICS_TAG,
  INDEX_URL,
  DOCS_URL,
  COMMUNITY_URL,
  HELP_URL,
  ARQUIVEI_JOBS_URL,
  HOTJAR_ID,
  IS_USING_SENTRY,
  SNOWPLOW_ENDPOINT,
  YOUTUBE_PLAYLIST_ID,
  GOOGLE_API_KEY,
};
